import { useSession } from "@src/app/store/helperHooks";
import { getWorkerQualifications } from "@src/utils/agent";
import { useMemo } from "react";

export function useWorkerQualifications() {
  const { agent } = useSession();

  const workerQualifications = useMemo((): string[] => {
    if (!agent) {
      return [];
    }
    return getWorkerQualifications(agent);
  }, [agent]);

  return workerQualifications;
}
