import { IonCard, IonCardContent, IonCardTitle, IonIcon, IonRow, IonText } from "@ionic/react";
import { LicenseStatuses } from "@src/appV2/Licenses/api/types";
import { card, chevronForwardOutline } from "ionicons/icons";
import moment from "moment-timezone";
import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
  LICENSE_CARD_BACKGROUND_COLORS,
  LICENSE_CARD_BORDER_STYLES,
  LICENSE_EXPIRY_DATE_COLORS,
  LICENSE_HEADER_CONTENT_COLORS,
  LICENSE_STATUSES_VALUE_MAPPING,
} from "./constants";
import { ILicenseDetails } from "./interfaces";

import "./style.scss";

const License: FC<{ license: ILicenseDetails }> = ({ license }) => {
  const history = useHistory();
  const onLicenseClick = () => history.push(`licenses/${license.id}/details`);

  const headerIonColor = useMemo(() => {
    let color = LICENSE_HEADER_CONTENT_COLORS.DEFAULT;
    if (license.status === LicenseStatuses.REJECTED) {
      color = LICENSE_HEADER_CONTENT_COLORS.REJECTED;
    }
    return color;
  }, [license.status]);

  const computedLicenseCardProps = useMemo(() => {
    const computedProps = {
      cardStyle: { background: LICENSE_CARD_BACKGROUND_COLORS.ACTIVE },
      status: license.status,
    };
    const daysUntilExpired = moment(license?.expiresAt).diff(moment(), "days", true); // gives diff in decimal
    if (license.status !== LicenseStatuses.ACTIVE) {
      computedProps.cardStyle["background"] = LICENSE_CARD_BACKGROUND_COLORS.INACTIVE;
    }
    if (daysUntilExpired <= 0 || license.status === LicenseStatuses.EXPIRED) {
      computedProps.cardStyle["expirationTextColor"] = LICENSE_EXPIRY_DATE_COLORS.EXPIRED;
      computedProps.cardStyle["border"] = LICENSE_CARD_BORDER_STYLES.EXPIRED;
      computedProps.cardStyle["background"] = LICENSE_CARD_BACKGROUND_COLORS.INACTIVE;
      computedProps.status = LicenseStatuses.EXPIRED;
    } else if (daysUntilExpired <= 90 && license.status === LicenseStatuses.ACTIVE) {
      // Expiring in 3 months
      computedProps.cardStyle["expirationTextColor"] = LICENSE_EXPIRY_DATE_COLORS.EXPIRING;
      computedProps.cardStyle["border"] = LICENSE_CARD_BORDER_STYLES.EXPIRING;
      computedProps.status = LicenseStatuses.EXPIRING;
    }
    if (license.status === LicenseStatuses.REJECTED) {
      computedProps.cardStyle["border"] = LICENSE_CARD_BORDER_STYLES.REJECTED;
    }
    return computedProps;
  }, [license.expiresAt, license.status]);

  return (
    <IonCard
      data-testid={`license-card-${license.number}`}
      aria-label="License Card"
      className="license-card"
      onClick={onLicenseClick}
      style={computedLicenseCardProps.cardStyle}
      key={license.id}
    >
      <IonRow className="license-card-header" no-padding>
        <IonRow class="ion-align-items-center">
          <IonIcon
            data-testid="license-card-header-left-icon"
            icon={card}
            style={{
              fontSize: "22px",
              color: headerIonColor,
              marginRight: "12px",
            }}
          />
          <IonCardTitle
            className="license-card-title"
            style={{ color: headerIonColor }}
            data-testid="license-title"
          >
            <span
              className="license-card-title-qualification"
              data-testid="license-title-qualification"
            >
              {license.qualification} -
            </span>
            <span aria-label={`License status is ${computedLicenseCardProps.status}`}>
              {`(${LICENSE_STATUSES_VALUE_MAPPING[computedLicenseCardProps.status]})`}
            </span>
          </IonCardTitle>
        </IonRow>
        <IonIcon
          data-testid="license-card-header-right-icon"
          icon={chevronForwardOutline}
          style={{ fontSize: "24px", color: headerIonColor }}
        />
      </IonRow>

      <IonCardContent className="license-card-content">
        <IonRow className="license-card-content-detail">
          <IonText>State</IonText>
          <IonText>
            {license.state} {license.multiState && "(multi-state)"}
          </IonText>
        </IonRow>
        <IonRow className="license-card-content-detail">
          <IonText>Number</IonText>
          <IonText>{license.number || "--"}</IonText>
        </IonRow>
        <IonRow className="license-card-content-detail">
          <IonText>Expires</IonText>
          <IonText
            data-testid="license-expiration-date"
            style={{
              color: computedLicenseCardProps.cardStyle["expirationTextColor"],
            }}
          >
            {license.expiresAt ? moment(license.expiresAt).format("DD MMM YYYY") : "--"}
          </IonText>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export { License };
