import { useSession } from "@src/app/store/helperHooks";
import { ILicensesCount } from "@src/lib/interface";

export function useLicenseCount(): {
  licensesCount: ILicensesCount;
  licensesNotificationCount: number;
} {
  const { agent } = useSession();
  const licensesCount = agent?.licensesCount ?? {
    PENDING: 0,
    ACTIVE: 0,
    EXPIRING: 0,
    EXPIRED: 0,
    REJECTED: 0,
  };

  const expiredLicenses = licensesCount.EXPIRED || 0;
  const expiringLicenses = licensesCount.EXPIRING || 0;
  const rejectedLicenses = licensesCount.REJECTED || 0;
  const licensesNotificationCount = rejectedLicenses + expiringLicenses + expiredLicenses;

  return { licensesCount, licensesNotificationCount };
}
